export interface WorkspaceSummaryDto {
    workspaceId: string
    log?: any[]
}

export interface YoioApp extends Object {
    key: string
    name?: string
    title?: string
    ogImage?: string
    description?: string
    landingPageText?: string
    uiUrl?: string
    betaLabelVisible?: boolean
    i18nNamespaceDefault?: string
    menuShowSubscriptionInfo?: boolean
}

export enum IssuerId {
    GOOGLE_OAUTH = 'GOOGLE_OAUTH',
    FIREBASE = 'FIREBASE',
    YOIO = 'YOIO',
}

export type WorkspaceJoinData = {
    userAccountId: string
    invitationTokenBase64: string
}

export enum AccessEvent {
    accessTokenRefreshed = 'access:accessTokenRefreshed',
    userLoginRequired = 'access:userLoginRequired',
}

export interface ScheduledTasksCollectionItem extends Object {
    _id: string
    key: string
    lastStartedAt?: string
    lastFinishedAt?: string
    workerId?: string
}

export interface AutomationCollectionItem extends Object {
    _id: string
    name: string
    createdAt: string
    environments?: string[]
    enabled?: boolean
    previewEnabled?: boolean
    configStr?: string
}

export interface AutomationLog extends Object {
    _id: string
    log?: string[]
}

export interface AutomationRunPreviewDto {
    invokedAt: string
    createdAt: string
    report?: any
    running: boolean
}